<template>
  <div>
    <div v-if="exchangeDecision === 'sellDeviza'" class="bg-default">
      <header class="header">
        <div class="header-left">
          <a href="#">
            <img
              class="header-settings-icon"
              src="img/settings-icon.png"
              alt="Settings"
            />
          </a>
        </div>
        <div class="header-center"></div>
        <div class="header-right">
          <a @click="exit()">
            <img
              class="header-settings-icon"
              src="img/exit-icon.png"
              alt="Exit"
            />
          </a>
        </div>
      </header>
      <main class="main">
        <div class="container">
          <div class="card card-800 card-centerred">
            <div class="card-icon card-icon-circle mb-48">
              <img src="img/success-icon.png" alt="Success icon" />
            </div>
            <div class="card-title mb-24"> {{ $t('rating-good-title') }} </div>
            <div class="card-info mb-86">
              {{ $t('rating-good-message') }} 
            </div>
            <div class="card-info"> 
              {{ $t('rating-good-change', { coin: maxBalance, money: deviza}) }} <br /> 
              {{ $t('rating-good-bonus') }} 
            </div>
          </div>
        </div>
      </main>
      <footer class="footer">
        <div class="container">
          <div class="footer-button-only">
            <a @click="goNext()" class="button button-blue">{{ $t('rating-btn') }}</a>
          </div>
        </div>
      </footer>
    </div>

    <div v-if="exchangeDecision === 'buyDeviza'" class="bg-default">
      <header class="header">
        <div class="header-left">
          <a href="#">
            <img
              class="header-settings-icon"
              src="img/settings-icon.png"
              alt="Settings"
            />
          </a>
        </div>
        <div class="header-center"></div>
        <div class="header-right">
          <a @click="exit()">
            <img
              class="header-settings-icon"
              src="img/exit-icon.png"
              alt="Exit"
            />
          </a>
        </div>
      </header>
      <main class="main">
        <div class="container">
          <div class="card card-800 card-centerred">
            <div class="card-icon card-icon-circle mb-32">
              <img src="img/fail-icon.png" alt="Fail icon" />
            </div>
            <div class="card-info mb-16">A deviza vásárlás során az</div>
            <div class="card-title mb-16">
              {{ $t('rating-bad-buy-deviza') }}
            </div>
            <div class="card-info mb-48">kell figyelembe venni</div>
            <div class="card-info">
              Segítségként devizában átváltottunk neked {{ maxBalance }} tallért
              {{ deviza }} dollárra.
            </div>
          </div>
        </div>
      </main>
      <footer class="footer">
        <div class="container">
          <div class="footer-button-only">
            <a @click="goNext()" class="button button-blue">Tőzsdézzünk!</a>
          </div>
        </div>
      </footer>
    </div>

    <div v-if="exchangeDecision === 'sellValuta'" class="bg-default">
      <header class="header">
        <div class="header-left">
          <a href="#">
            <img
              class="header-settings-icon"
              src="img/settings-icon.png"
              alt="Settings"
            />
          </a>
        </div>
        <div class="header-center"></div>
        <div class="header-right">
          <a @click="exit()">
            <img
              class="header-settings-icon"
              src="img/exit-icon.png"
              alt="Exit"
            />
          </a>
        </div>
      </header>
      <main class="main">
        <div class="container">
          <div class="card card-800 card-centerred">
            <div class="card-icon card-icon-circle mb-32">
              <img src="img/fail-icon.png" alt="Fail icon" />
            </div>
            <div class="card-info mb-16">A tőzsdei kereskedéshez</div>
            <div class="card-title mb-16">
              Nem valutára,<br />
              hanem devizára
            </div>
            <div class="card-info mb-48">van szükséged</div>
            <div class="card-info">
              Segítségként devizában átváltottunk neked {{ maxBalance }} tallért
              {{ deviza }} dollárra.
            </div>
          </div>
        </div>
      </main>
      <footer class="footer">
        <div class="container">
          <div class="footer-button-only">
            <a @click="goNext()" class="button button-blue">Tőzsdézzünk!</a>
          </div>
        </div>
      </footer>
    </div>

    <div v-if="exchangeDecision === 'buyValuta'" class="bg-default">
      <header class="header">
        <div class="header-left">
          <a href="#">
            <img
              class="header-settings-icon"
              src="img/settings-icon.png"
              alt="Settings"
            />
          </a>
        </div>
        <div class="header-center"></div>
        <div class="header-right">
          <a @click="exit()">
            <img
              class="header-settings-icon"
              src="img/exit-icon.png"
              alt="Exit"
            />
          </a>
        </div>
      </header>
      <main class="main">
        <div class="container">
          <div class="card card-800 card-centerred">
            <div class="card-icon card-icon-circle mb-32">
              <img src="img/fail-icon.png" alt="Fail icon" />
            </div>
            <div class="card-info mb-16">A tőzsdei kereskedéshez</div>
            <div class="card-title mb-48">
              Devizát<br />
              kell venned
            </div>
            <div class="card-info">
              Segítségként devizában átváltottunk neked {{ maxBalance }} tallért
              {{ deviza }} dollárra.
            </div>
          </div>
        </div>
      </main>
      <footer class="footer">
        <div class="container">
          <div class="footer-button-only">
            <a @click="goNext()" class="button button-blue">Tőzsdézzünk!</a>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Rating",
  data: function () {
    return {
      maxBalance: 50000,
      deviza: 0,
    };
  },
  computed: {
    ...mapGetters(["exchangeDecision", "balance", "groupId","actualGameTurnId", "stopGameTurnId"]),
  },
  methods: {
    goNext() {
      if (this.groupId) {
        if(this.actualGameTurnId === this.stopGameTurnId) {
          return this.$router.push("waiting");
        }
        return this.$router.push("waitingCounter2")
      }
      this.$store.commit("setStatus", "deny");
      this.$router.push("waitingcounter2");
    },
    exchange() {
      let deviza = null;
      let maxBalance = 50000;
      if (this.balance < 50000) {
        maxBalance = this.balance;
      }
      this.maxBalance = maxBalance;
      deviza = Math.round(maxBalance / this.$config.game.prices.devizaSellRate);
      // console.log("deviza", deviza);
      this.deviza = deviza;
      this.$store.commit("setDeviza", deviza);

      this.$store.commit("setBalance", this.balance - maxBalance);
    },
    /* addBonus() {
      let newBalance = this.balance + this.$config.game.bonuses.exchangeBonus;
      this.$store.commit("setBalance", newBalance);
    }, */
  },

  created() {
    // console.log("ex", this.exchangeDecision);
    this.exchange();
    this.$store.dispatch('saveBalanceChange', {balance:(this.maxBalance * -1)})
    if (this.exchangeDecision === "sellDeviza") {
      //this.addBonus(this.$config.game.bonuses.exchangeBonus);
      this.$store.commit("setDevizaBonus", true);
    }
  },
  mounted() {
    // console.log("ASDSADSA");
  },
};
</script>

<style>
</style>
